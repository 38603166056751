import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePartners } from '../../hooks/use-partners'
import { makeRequest, changelanguages } from '../../helpers'
import PageView from './page-view'

const PageController = (props) => {
  const { pathname } = props.location
  const slug = _.replace(pathname, '/', '')
  const [terms, setTerms] = useState([])
  const { loading, partners } = usePartners()
  const [loadingHome, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const getTerms = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug,
      },
    }).then((resp) => {
      setTerms(resp.data[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getTerms()
  }, [i18n.language])

  const viewProps = {
    loading,
    partners,
    loadingHome,
    data: terms,
    t,
  }
  return (
    <PageView {...viewProps} />

  )
}

export default PageController
