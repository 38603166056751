import _ from 'lodash'
import React from 'react'
import { withTheme } from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  H1, Seo, Wrapper, Spinner,
} from '../../components'
import './page-styles.scss'

const PageView = ({
  data, loading,
}) => {
  if (loading) {
    return <Spinner loading={loading} />
  }
  const breakpoints = useBreakpoint()
  return (

    <React.Fragment>
      <Seo
        title={_.get(data, 'yoast_head_json.title')}
        description={_.get(data, 'yoast_head_json.description')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, 'yoast_head_json.og_type', {})}
      />
      <Wrapper
        justifyContentD="flex-start"
        alignItemsM="flex-start"
        widthD="1240px"
        flexDirectionD="column"
        paddingD="0 10px"
        paddingM="0 26px"
        marginM="0 0 120px"
        className="container-page">
        <H1
          style={{
            margin: breakpoints.xs ? '40px 0' : '48px 0',
          }}>{_.get(data, 'title.rendered')} </H1>
        <Wrapper
          justifyContentD="flex-start"
          alignItemsD="flex-start"
          widthD="890px"
          flexDirectionD="column"
          marginD="0"
          className="container">
          <div className="rich-text" dangerouslySetInnerHTML={{ __html: _.get(data, 'content.rendered') }} />
        </Wrapper>
      </Wrapper>

    </React.Fragment>
  )
}

export default withTheme(PageView)
